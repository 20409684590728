import React from "react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./components/home";
import Results from "./components/resultsPage";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <>
      <ToastContainer />
      <Switch>
        <Route path="/" exact component={Home}></Route>
        <Route path="/results" exact component={Results}></Route>
      </Switch>
    </>
  );
}

export default App;
