import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const ResultMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{ ...props.geometry.location }}
      key="AIzaSyB3EdglXyf2RJs3r8JgHBYgawYWTfBr874"
    >
      {props.geometry && <Marker position={{ ...props.geometry.location }} />}
    </GoogleMap>
  ))
);

export default ResultMap;
