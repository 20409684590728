import axios from "axios";

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  endpoint:
    process.env.REACT_APP_SERVER_ENDPOINT || "https://norma-realty.appspot.com",
};
