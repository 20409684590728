import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const dropdownItemStyle = {
  cursor: "pointer",
};

const AddressDropdown = ({ suggestions, onInputSelect }) => {
  return (
    <div
      id="addressDropdown"
      className="dropdown-menu"
      style={{ display: suggestions.length > 1 ? "block" : "none" }}
    >
      {suggestions.map((suggestion) => (
        <div
          className="dropdown-item"
          key={suggestion.id}
          style={{
            ...dropdownItemStyle,
            width: document.getElementById("addressInput").clientWidth + "px",
          }}
          onClick={() => onInputSelect(suggestion.description)}
        >
          <span>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </span>{" "}
          {suggestion.description}
        </div>
      ))}
    </div>
  );
};

export default AddressDropdown;
