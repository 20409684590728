import React, { useState, useEffect } from "react";
import HomeCenter from "./homeCenter";
import HomeTop from "./homeTop";
import { placesAutoComplete, addressGeoLocation } from "./../services/places";

const Home = ({ history }) => {
  const [inputQuery, setInputQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  function handleInputChange(value) {
    setInputQuery(value);
  }

  function handleInputSelect(value) {
    setInputQuery(value);
  }

  function handleSearch() {
    addressGeoLocation(inputQuery).then((response) => {
      history.push({
        pathname: "/results",
        state: {
          geometry: response.data.geometry,
          address: response.data.formatted_address,
        },
      });
    });
  }

  useEffect(() => {
    if (!inputQuery) {
      setSuggestions([]);
    } else {
      placesAutoComplete(inputQuery).then((response) => {
        setSuggestions(response.data);
      });
    }
  }, [inputQuery]);

  return (
    <div className="container-fluid" style={{ height: "100vh" }}>
      <HomeTop />
      <HomeCenter
        inputQuery={inputQuery}
        onInputChange={handleInputChange}
        suggestions={suggestions}
        onInputSelect={handleInputSelect}
        handleSearch={handleSearch}
      />
    </div>
  );
};

export default Home;
