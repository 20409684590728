import React from "react";

const BusinessCard = () => {
  return (
    <div className="media eddie mt-2 ml-3">
      <div>
        <div className="row">
          <img
            src="/images/headshot.jpg"
            className="mr-3 headshot-image"
            alt="..."
          />
          <div className="media-body eddie">
            <h5 className="mt-0">Norma Mora</h5>Licensed Real Estate Agent
            <br />
            (813) 401-1441
            <br />
            nmora@graystoneig.com
          </div>
        </div>
        <div className="row">
          <img src="/images/logo.png" className="logo-image" alt="" />
        </div>
      </div>
      <br />
    </div>
  );
};

export default BusinessCard;
