import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AddressDropdown from "./addressDropdown";

const HomeCenter = ({
  inputQuery,
  onInputChange,
  suggestions,
  onInputSelect,
  handleSearch,
}) => {
  return (
    <>
      <div
        className="mx-auto my-auto"
        style={{
          maxWidth: "700px",
          verticalAlign: "middle",
          position: "relative",
          top: "40%",
          color: "white",
        }}
      >
        <h1 className="text-center">What's my home worth?</h1>
      </div>
      <div
        className="input-group input-group-lg mx-auto my-auto"
        style={{
          maxWidth: "700px",
          verticalAlign: "middle",
          position: "relative",
          top: "44%",
        }}
      >
        <input
          type="text"
          id="addressInput"
          className="form-control"
          placeholder="Search address..."
          value={inputQuery}
          onChange={(e) => onInputChange(e.target.value)}
        />
        <AddressDropdown
          suggestions={suggestions}
          onInputSelect={onInputSelect}
        />
        <div className="input-group-append">
          <button className="btn btn-primary" onClick={handleSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
    </>
  );
};

export default HomeCenter;
