import React, { useState, useEffect } from "react";
import ResultMap from "./map";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.us";
import { sendEmail } from "./../services/form";
import MapModal from "./mapModal";
import BusinessCard from "./businessCard";

const cardStyles = {
  color: "black",
};

const formBlockStyles = {
  position: "relative",
  left: "0px",
  right: "0px",
  top: "25%",
  backgroundColor: "rgb(0, 0, 0, 0.4)",
  borderRadius: "15px",
  maxWidth: "600px",
  margin: "auto",
};

const Results = ({ location, history }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [sellHouse, setSellHouse] = useState("");
  const { register, handleSubmit, errors } = useForm();

  console.log(errors);

  const handleFormSubmit = () => {
    sendEmail({
      fullName,
      email,
      phone,
      sellHouse,
      address: location.state.address,
    }).then((response) => {
      toast.info(
        "Your request has been submitted! We will reach out to your email address.",
        {
          autoClose: 5000,
          onOpen: () => {
            history.push("/");
          },
        }
      );
    });
  };

  if (!location.state) return window.location.replace("/");

  return (
    <div className="container-fluid" style={{ height: "100vh" }}>
      <div className="row">
        <div className="col-md-6">
          <Link
            className="btn btn-outline-info mt-2 position-absolute semi-dark-bg"
            to="/"
          >
            Go Back
          </Link>
          <div className="d-flex justify-content-center">
            <BusinessCard />
          </div>
          <div style={formBlockStyles}>
            <h3 className="text-center">We found it!</h3>
            <p className="text-center small">
              <FontAwesomeIcon icon={faMapMarkerAlt} /> {location.state.address}
            </p>
            <div className="text-center d-block d-md-none">
              <button
                className="btn btn-sm btn-dark mb-2"
                data-toggle="modal"
                data-target="#mapModal"
              >
                View Map
              </button>
            </div>
            <p className="text-center">
              Please let us know where to send your home valuation report.
            </p>
            <div className="card" style={cardStyles}>
              <div className="card-body">
                <form
                  className="needs-validation"
                  novalidate
                  onSubmit={handleSubmit(handleFormSubmit)}
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className={
                        errors.fullName
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      id="fullName"
                      name="fullName"
                      placeholder="Full Name"
                      ref={register({ required: true })}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    {errors.fullName && (
                      <div className="invalid-feedback d-block">
                        Name is required.
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className={
                        errors.emailAddress
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      id="emailAddress"
                      name="emailAddress"
                      placeholder="Email Address"
                      ref={register({ required: true })}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.emailAddress && (
                      <div className="invalid-feedback d-block">
                        Email address is required.
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <Cleave
                      placeholder="Phone Number (Optional)"
                      options={{
                        blocks: [0, 3, 3, 4],
                        delimiters: ["(", ") ", "-"],
                      }}
                      className="form-control"
                      id="phoneNumber"
                      name="phoneNumber"
                      ref={register}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <select
                      name="whentosell"
                      id="whentosell"
                      className={
                        errors.fullName
                          ? "custom-select is-invalid"
                          : "custom-select"
                      }
                      ref={register({ required: true })}
                      onChange={(e) => setSellHouse(e.target.value)}
                    >
                      <option value="" selected disabled>
                        When do you plan to sell?
                      </option>
                      <option value="Just looking">Just looking</option>
                      <option value="Sometime in the future">
                        Sometime in the future
                      </option>
                      <option value="As soon as possible">
                        As soon as possible
                      </option>
                    </select>
                    {errors.whentosell && (
                      <div className="invalid-feedback d-block">
                        Please select a value.
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-block btn-outline-success semi-dark-bg"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-none d-md-block">
          <ResultMap
            geometry={location.state.geometry}
            loadingElement={<p>Loading...</p>}
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB3EdglXyf2RJs3r8JgHBYgawYWTfBr874"
            mapElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100vh` }} />}
          />
        </div>
      </div>
      <MapModal address={location.state.address} id="mapModal">
        <ResultMap
          geometry={location.state.geometry}
          loadingElement={<p>Loading...</p>}
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB3EdglXyf2RJs3r8JgHBYgawYWTfBr874"
          mapElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: "535px" }} />}
        />
      </MapModal>
    </div>
  );
};

export default Results;
